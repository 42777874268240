import { createGlobalState } from "@vueuse/core";
import { shallowRef, computed } from "vue";
import { useRuntimeConfig } from "#imports";

export const useBreakUi = createGlobalState(() => {
  const config = useRuntimeConfig();
  const value = shallowRef(false);
  const depth = shallowRef(15);
  const broken = computed(
    () => config.public.environment === "local" && value.value
  );

  function toggle() {
    value.value = !value.value;
  }

  return { broken, depth, toggle };
});
